import React from "react";
import { useAuthContext } from "../../contexts/authContext";
import PageLayout from "../../components/PageLayout";
import PageContent from "../../components/PageContent";
import PageBoundary from "../../components/PageBoundary";
import LoadingStatus from "../../components/LoadingStatus";
import BannerComponent from "../../components/Banner";
import clsx from "clsx";
import EquitiesInsightsPage from "../../components/EquitiesInsightsPage";
import MacroInsightsPage from "../../components/MacroInsightsPage";
import { useInsightsContext } from "../../contexts/insightsContext";
import ThemesInsightsPage from "../../components/ThemesInsightsPage";
import AudioDeepDivesInsightsPage from "../../components/AudioDeepDivesInsightsPage";
import CommoditiesInsightsPage from "../../components/CommoditiesInsightsPage";
import PrivatesPage from "../../components/Privates";
import SelectDropdown from "../../components/SelectDropdown";

const InsightsPage: React.FC = () => {
  const { accessToken } = useAuthContext();

  // tabs
  const {
    tabs,
    activeTab,
    setActiveTab,
    canViewMacro,
    canViewThemes,
    canViewCommodities,
    canViewAudioDeepDives,
  } = useInsightsContext();

  const tempSEOData = {
    title: "BAM Applied Insights",
    description: "Balyasny's AI-Enabled Research Platform for Core Investors",
  };

  const tempBannerData = {
    title1: "Access AI-Enabled research from BAM's proprietary catalogue",
    description: `Welcome to Balyasny's new AI-enabled research platform created exclusively for our Core Investors. This tool generates robust summaries from our research library. BAM is breaking ground as the first multi-strategy firm to offer its investors such a platform, representing our commitment to transparency and partnership.`,
  };

  return (
    <PageLayout seo={tempSEOData}>
      <PageContent className="flex flex-col">
        {!accessToken ? (
          <LoadingStatus loadingType="page_error" />
        ) : (
          <div className="flex flex-col">
            <BannerComponent
              title1={tempBannerData.title1}
              description={tempBannerData.description}
            />
            {tabs.length > 1 && (
              <PageBoundary className="w-full">
                <div className="grid grid-cols-1 md:hidden pt-6 mb-3">
                  <SelectDropdown
                    label="Report Type"
                    value={activeTab}
                    onChange={(event) =>
                      setActiveTab(event.target.value as any)
                    }
                    options={tabs.map((tab) => ({ name: tab, value: tab }))}
                  />
                </div>
                <div className="hidden md:block pt-6 mb-3 border-b border-bam-shade-gray">
                  <nav
                    aria-label="Tabs"
                    className="flex -mb-px space-x-8 sm:space-x-12"
                  >
                    {tabs.map((tab) => (
                      <button
                        key={tab}
                        onClick={() =>
                          setActiveTab(tab as "Macro" | "Equities")
                        }
                        className={clsx(
                          "font-medium text-sm sm:text-base md:text-lg border-b-2 py-1.5",
                          activeTab === tab
                            ? "text-blue-400 border-blue-400 font-semibold"
                            : "text-black border-transparent hover:border-b-bam-medium-gray hover:text-bam-gray-600",
                        )}
                      >
                        {tab}
                      </button>
                    ))}
                  </nav>
                </div>
              </PageBoundary>
            )}
            {activeTab === "Macro" && canViewMacro && <MacroInsightsPage />}
            {activeTab === "Equities" && <EquitiesInsightsPage />}
            {activeTab === "Themes" && canViewThemes && <ThemesInsightsPage />}
            {activeTab === "Commodities" && canViewCommodities && (
              <CommoditiesInsightsPage />
            )}
            {activeTab === "Audio Deep Dives" && canViewAudioDeepDives && (
              <AudioDeepDivesInsightsPage />
            )}
            {activeTab === "Privates" && canViewAudioDeepDives && (
              <PrivatesPage />
            )}
          </div>
        )}
      </PageContent>
    </PageLayout>
  );
};

export default InsightsPage;
