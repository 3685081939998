import React from "react";
import { Link } from "gatsby";
import { MacroDocumentAttributes } from "../../types/cms";
import { getFormattedDate } from "../../utils";
import clsx from "clsx";
import NewTag from "../NewTag";

interface MacroReportCardProps {
  document: MacroDocumentAttributes;
  reportDetailsView?: boolean;
}

const MacroReportCard: React.FC<MacroReportCardProps> = ({
  document: { report_id, title, report_date, byline, summary },
  reportDetailsView,
}) => {
  const encodedReportId = encodeURIComponent(report_id);
  const reportDate = getFormattedDate(report_date);
  return (
    <Link to={`/insights/macro-reports?report_id=${encodedReportId}`}>
      <div className="flex flex-col w-full h-full overflow-hidden px-4 py-3 bg-white hover:shadow-lg">
        <div className="flex flex-col">
          <div
            className={clsx(
              "text-xl font-semibold text-blue-500 mt-1.5",
              reportDetailsView ? "" : "xs:text-2xl",
            )}
          >
            {title}
          </div>
          <div className="mt-2 xs:mt-3 text-sm xs:text-base text-black flex justify-between">
            <div className="py-0.5 align-middle">{reportDate}</div>
            <NewTag reportDate={report_date} />
          </div>
          <div className="my-2 xs:my-3 pb-1 text-sm text-gray-800 leading-relaxed">
            {byline}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default MacroReportCard;
