import React from "react";
import { DocumentAttributes, TickerAttributes } from "../../types/cms";
import { Link } from "gatsby";
import { getFormattedReportMetada } from "../../utils";
import clsx from "clsx";
import NewTag from "../NewTag";

interface ReportCardProps {
  document: DocumentAttributes;
  associated_ticker?: TickerAttributes;
  reportDetailsView?: boolean;
}

const ReportCard: React.FC<ReportCardProps> = ({
  document,
  associated_ticker,
  reportDetailsView,
}) => {
  const {
    encodedReportId,
    ticker,
    companyName,
    reportingPeriod,
    overview,
    logo_url,
  } = getFormattedReportMetada(document, associated_ticker);
  return (
    <Link to={`/insights/reports?report_id=${encodedReportId}`}>
      <div className="flex flex-col w-full h-full overflow-hidden px-4 py-3 bg-white hover:shadow-lg">
        {!reportDetailsView && (
          <div className="flex items-center h-12 xs:h-14">
            <h2 className="flex-1 text-3xl xs:text-4xl tracking-wide font-bold text-blue-500">
              {ticker}
            </h2>
            <div className="flex-shrink-0 inline-block">
              {logo_url && (
                <img
                  src={logo_url}
                  alt={`Logo for ${companyName}`}
                  className="w-12 h-12 xs:w-14 xs:h-14"
                />
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col">
          {!reportDetailsView && (
            <div className="line-clamp-2 text-xl xs:text-2xl font-semibold text-gray-900">
              {companyName}
            </div>
          )}
          <div
            className={clsx(
              "mt-2 xs:mt-3 text-sm xs:text-base",
              reportDetailsView
                ? " text-gray-900 font-semibold"
                : "text-gray-800",
            )}
          >
            {reportingPeriod}
          </div>
          <div className="mt-1 xs:mt-1.5 text-sm xs:text-base flex justify-between">
            <div className="py-0.5 align-middle"></div>
            <NewTag reportDate={document.report_date} />
          </div>
        </div>
        <div
          className={clsx(
            "my-2 xs:my-3 pb-1 text-sm text-gray-800",
            reportDetailsView ? "line-clamp-4 leading-relaxed" : "line-clamp-3",
          )}
        >
          {overview}
        </div>
      </div>
    </Link>
  );
};

export default ReportCard;
