import React from "react";
import { Link } from "gatsby";
import { formatReportingPeriod } from "./utils";

import { StarIcon } from "@heroicons/react/24/solid";
import Tooltip from "../Tooltip";
import NewTag from "../NewTag";

interface ReportCardProps {
  document: any;
  id: string;
}

const ReportCard: React.FC<ReportCardProps> = ({ document, id }) => {
  const { company_name, report_start_date, report_date, summary, company } =
    document;

  return (
    <Link to={`/insights/privates-reports?id=${id}`}>
      <div className="flex flex-col w-full h-full overflow-hidden px-4 py-3 bg-white hover:shadow-lg">
        <div className="flex items-center justify-between h-20">
          <h2 className="flex-1 font-bold text-2xl line-clamp-2 mr-2 text-blue-500">
            {company_name}
          </h2>
          <div className="flex">
            {company.data.attributes.is_featured && (
              <div>
                <Tooltip text="Featured" width="90px">
                  <StarIcon className="h-6 text-blue-400" />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className="grow"></div>
        <div className="flex flex-col">
          <div className="mt-2 xs:mt-3 text-gray-800">
            {formatReportingPeriod(report_start_date, report_date)}
          </div>
          <div className="mt-1 xs:mt-1.5 text-sm xs:text-base flex justify-between">
            <div className="py-0.5 align-middle"></div>
            <NewTag reportDate={document.report_date} />
          </div>
        </div>
        <div className="my-2 xs:my-3 pb-1 line-clamp-3 text-sm text-gray-600">
          {summary}
        </div>
      </div>
    </Link>
  );
};

export default ReportCard;
