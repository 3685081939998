import React from "react";
import { ThemesDocumentAttributes } from "../../types/cms";
import { getFormattedDate } from "../../utils";
import { Link } from "gatsby";
import clsx from "clsx";
import NewTag from "../NewTag";

interface ThemesReportCardPropd {
  document: ThemesDocumentAttributes;
  reportDetailsView?: boolean;
}

const ThemesReportCard: React.FC<ThemesReportCardPropd> = ({
  document: { report_id, report_date, basket_name, summary },
  reportDetailsView,
}) => {
  const encodedReportId = encodeURIComponent(report_id);
  const reportDate = getFormattedDate(report_date);

  return (
    <Link to={`/insights/themes-reports?report_id=${encodedReportId}`}>
      <div className="flex flex-col w-full h-full overflow-hidden px-4 py-3 bg-white hover:shadow-lg">
        <div className="flex flex-col">
          <div className={clsx("text-xl font-semibold text-blue-500 mt-1.5")}>
            {basket_name}
          </div>
          <div className="mt-2 xs:mt-3 text-sm xs:text-base text-black flex justify-between">
            <div className="py-0.5 align-middle">{reportDate}</div>
            <NewTag reportDate={report_date} />
          </div>
          <div
            className={clsx(
              "my-2 xs:my-3 pb-1 text-sm text-gray-600",
              reportDetailsView
                ? "line-clamp-3 leading-relaxed"
                : "line-clamp-4 leading-relaxed",
            )}
          >
            {summary}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ThemesReportCard;
